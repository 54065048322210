.transactiondialog {
  .headerText {
    font-size: 22px;
    font-weight: 600;
  }
  .headerSummary {
    font-size: 16px;
    padding-top: 25px;
  }
  /deep/ .el-dialog {
    background: $theme-hue !important;

    .el-dialog__body {
      padding-top: 10px;
      text-align: center;
      .searchForm {
        margin-top: 20px;
        display: flex;
        gap: 15px;

        .blue_button {
          height: 42px;
          margin-bottom: 20px;
        }
      }
      .type {
        width: 185px;
      }

      .date {
        width: 280px;
      }
    }
    .trpagin {
      .el-select {
        height: 28px !important;
        .el-input__inner {
          height: 28px !important;
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .transactiondialog {
    /deep/ .el-dialog {
      .el-dialog__body {
        .searchForm {
          display: block;
          .type {
            width: 100%;
          }

          .date {
            width: 100%;
            margin-bottom: 15px;
          }
          .blue_button {
            width: 100%;
          }
        }
      }
    }
  }
}
