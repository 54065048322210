.tradingdialog {
  .headerText {
    font-size: 22px;
    font-weight: 600;
  }
  /deep/ .el-dialog {
    background: $theme-hue !important;

    .el-dialog__body {
      padding-top: 10px;
      text-align: center;
    }
    .trpagin {
      .el-select {
        height: 28px !important;
        .el-input__inner {
          height: 28px !important;
        }
      }
    }
    .calendar_content_main {
      width: 100%;
      display: grid;
      align-items: center;
      grid-template-columns: 65% 12%;
      column-gap: 3%;
      margin-bottom: 30px;
      .date_container {
        display: flex;
        height: 45px;
        .calendar_to{
          flex-shrink: 0;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .tradingdialog {
    .headerText {
      font-size: 22px;
      font-weight: 600;
    }
    /deep/ .el-dialog {
      .calendar_content_main {
        display: block;
        .date_container {
          height: unset;
        }
        .btn_red {
          margin-top: 15px;
        }
      }
    }
  }
}
