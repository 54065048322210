
              @import "@/assets/css/au.scss";
            

@import './style.scss';

.retailAccsTable {
  /deep/ th {
    text-transform: uppercase;
  }
}
