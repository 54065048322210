#retail_accounts {
  .retail_content {
    .retail_search {
      display: flex;
      gap: 15px;

      .searchField {
        /deep/ .el-form-item__content {
          border: solid 1px $white;

          .el-input__inner {
            border: unset;
            font-size: 14px;
          }
        }
      }

      .type {
        width: 185px;
      }

      .category {
        width: 185px;
      }

      .content {
        width: 300px;
        /deep/ .el-form-item__content {
          .el-input__inner {
            height: 45px !important;
          }
        }
      }
      .searchbtn {
        height: 45px;
      }
    }

    .table_content {
      width: 100%;

      > li {
        width: 100%;
        @include rtl-sass-value(float, left, right);
        display: none;
      }

      > li.active {
        display: block;
      }

      .table_box {
        margin-bottom: 30px;

        /deep/ .el-table {
          .cell {
            word-break: break-word;
            padding: 0;

            .el-dropdown-link {
              cursor: pointer;
              color: $white;
            }

            .el-icon-more {
              color: $white;
              transform: rotate(90deg);
            }
          }

          th {
            line-height: 51px;
          }
        }
      }
    }
  }
}

@media (max-width: 1100px) {
  #retail_accounts {
    .retail_content {
      .retail_search {
        display: block;
        .type {
          width: 100%;
        }

        .category {
          width: 100%;
        }

        .content {
          width: 100%;
        }
      }
    }
  }
}
